require([], function () {
    const storage = window.localStorage,
        customerData = {},
        keys = [
            'visitorLifetimeValue',
            'visitorId',
            'visitorEmailHash'
        ];
    window.dataLayer = window.dataLayer || [];

    const cacheStorage = JSON.parse(storage.getItem('mage-cache-storage')) || null;
    if (cacheStorage && cacheStorage.gtm && cacheStorage.gtm.datalayer) {
        JSON.parse(cacheStorage.gtm.datalayer).map(item => {
            keys.forEach(key => {
                if (item[key]) {
                    customerData[key] = item[key];
                }
            })
        });
    }

    if (Object.keys(customerData).length > 0) {
        window.dataLayer.push(customerData);
    }
});
